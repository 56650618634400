<template>
    <div class="home__content">
        <div class="section main">
            <div class="section__inner main__inner">
                <div class="main__left">
                    <div class="main__title">Работайте из дома и зарабатывайте в интернете</div>
                    <ul class="main__about">
                        <li class="main__about-item">Используйте как подработку</li>
                        <li class="main__about-item">Выводите деньги на карту</li>
                        <li class="main__about-item">Получайте кешбэк за покупки</li>
                    </ul>
                    <div class="buttons-group">
                        <button class="j-reg_init button-item button-item--white main__button">
                            Начать зарабатывать
                        </button>
                        <button class="j-reg_init button-item button-item--transparent main__button">
                            Быстрый доход
                        </button>
                    </div>
                </div>
                <div class="main__picture" />
            </div>
        </div>
        <div class="section counters">
            <div class="section__inner counters__inner">
                <div
                    v-for="item in counters"
                    class="counters__item"
                    :key="item.text"
                >
                    <div class="counters__item-value">
                        {{ item.value | toRoublesLocale }}
                    </div>
                    <div
                        class="counters__item-about"
                        v-text="item.text"
                    />
                </div>
            </div>
        </div>
        <div class="section candidates" id="for-who">
            <div class="section__inner">
                <div class="candidates__title section__title section__title--black">
                    Кому это подойдёт
                </div>
                <div class="candidates__group">
                    <div
                        style="display: none;"
                        v-html="WorkersIcon"
                    />
                    <div
                        class="candidate"
                        v-for="(item, index) in candidates"
                        :key="item.title"
                        @click="openCandidateModal(index)"
                    >
                        <svg
                            slot="icon"
                            class="icon"
                        >
                            <use :xlink:href="`#${item.alias}`" />
                        </svg>
                        <div
                            class="candidate__title"
                            v-text="item.title"
                        />
                        <div
                            class="candidate__about"
                            v-html="item.about"
                        />
                    </div>
                </div>
                <div class="section__button candidates__button">
                    <w-button class="j-reg_init">
                        Это для меня
                    </w-button>
                </div>
            </div>
        </div>
        <div class="section partners">
            <div class="section__inner">
                <div class="partners__title section__title section__title--black">
                    Станьте представителем ведущих компаний
                </div>
                <div class="partners__wrapper">
                    <div class="partners__carousel">
                        <div class="swiper-wrapper">
                            <base-carousel>
                                <template #slides>
                                    <div
                                        v-for="item in partnerCompanies"
                                        class="partners__slide"
                                        :key="item"
                                    >
                                        <img :src="item" />
                                    </div>
                                </template>
                            </base-carousel>
                        </div>
                    </div>
                </div>
                <div class="section__button">
                    <w-button class="j-reg_init">
                        Зарегистрироваться
                    </w-button>
                </div>
            </div>
        </div>
        <div class="section products section--grey">
            <div class="section__inner">
                <div class="products__title section__title section__title--black">
                    В каталоге Workle найдется все
                </div>
                <div class="products__about">
                    Выберите любое предложение, чтобы начать экономить или зарабатывать.
                </div>
                <div class="products__group">
                    <div class="products__group products__group--left">
                        <div class="product product--big">
                            <div class="product__picture">
                                <img :src="financeProduct.picture">
                            </div>
                            <div
                                class="product__title finance-product__title"
                                v-text="financeProduct.title"
                            />
                            <ul class="product__categories product__categories--big">
                                <li
                                    v-for="(category, index) in financeProduct.categories"
                                    class="product__category product__category--wide"
                                    :key="index"
                                    v-text="category"
                                />
                            </ul>
                            <div class="product__profit finance-product__profit">
                                Доход до <b>{{ financeProduct.profit }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="products__group products__group--right">
                        <div
                            v-for="item in productItem.slice(1)"
                            class="product"
                            :key="item.title"
                        >
                            <div class="product__picture">
                                <img :src="item.picture">
                            </div>
                            <div
                                class="product__title"
                                v-text="item.title"
                            />
                            <ul class="product__categories">
                                <li
                                    v-for="(category, index) in item.categories"
                                    class="product__category"
                                    :key="index"
                                    v-text="category"
                                />
                            </ul>
                            <div class="product__profit">
                                Доход до <b>{{  item.profit }}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section__button products__button">
                    <w-button class="j-reg_init">
                        Показать все услуги
                    </w-button>
                </div>
            </div>
        </div>
        <div class="section" id="steps">
            <div class="section__inner">
                <div class="section__title section__title--black">
                    Ваши 4 шага до первого заработка
                </div>
                <div class="step__wrapper">
                    <div class="step__inner"
                        v-for="(item, index) in steps"
                        :key="index"
                    >
                        <div class="step">
                            <div class="step__logo icon">
                                {{ index + 1 }}
                            </div>
                            <div
                                class="step__about"
                                v-html="item"
                            />
                        </div>
                        <div class="step__arrow-wrapper">
                            <arrow-icon class="step__arrow" />
                        </div>
                    </div>
                </div>
                <div class="section__button">
                    <w-button class="j-reg_init">
                        Начать зарабатывать
                    </w-button>
                </div>
                <div class="help">
                    <div class="help__title section__title section__title--black">
                        Зарабатывайте официально, даже если нет опыта
                    </div>
                    <div class="help__content">
                        <div class="help-item__wrapper">
                            <div class="help-item">
                                <div class="help-item__title">Вы быстро всему научитесь</div>
                                <div class="help-item__about">
                                    Переживаете, что нет опыта? Ничего страшного! Для этого на сайте есть бесплатное онлайн-обучение. В нем пошагово объясняется, как и на чем можно быстро заработать. Вам остается лишь повторить.
                                </div>
                            </div>
                            <div class="help-item">
                                <div class="help-item__title">
                                    Если вы профи, продолжайте расти
                                </div>
                                <div class="help-item__about">
                                    Мы понимаем, что даже профессионалам нужно пробовать новые способы заработка. Бесплатные курсы и видео по интернет-рекламе, генерации трафика, прибыльным направлениям на сайте помогут увеличить доход. 
                                </div>
                            </div>
                        </div>
                        <div class="help__picture" />
                    </div>
                    <div class="section__button help__button">
                        <w-button class="j-reg_init">
                            Хочу зарабатывать
                        </w-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="section opportunities section--grey">
            <div class="section__inner">
                <div class="opportunities__title section__title section__title--black">
                    Откройте для себя новые возможности
                </div>
                <div class="opportunities__group">
                    <div
                        style="display: none;"
                        v-html="OpportunitiesIcon"
                    />
                    <div
                        v-for="item in opportunities"
                        class="opportunity"
                        :key="item.title"
                    >
                        <svg
                            slot="icon"
                            class="icon"
                        >
                            <use :xlink:href="`#${item.alias}`" />
                        </svg>
                        <div class="opportunity__title" v-text="item.title" />
                        <div class="opportunity__about" v-html="item.about" />
                    </div>
                </div>
                <div class="section__button">
                    <w-button class="j-reg_init">
                        Начать зарабатывать
                    </w-button>
                </div>
            </div>
        </div>
        <div class="section success" id="users">
            <div class="section__inner">
                <div class="success__title section__title section__title--black">
                    Отзывы наших пользователей
                </div>
                <div class="success-group">
                    <div class="swiper__wrapper">
                        <base-carousel>
                            <template #slides>
                                <div
                                    v-for="(item, index) in success"
                                    class="success__item-wrapper"
                                    :key="item.title"
                                >
                                    <div class="success__item">
                                        <div
                                            class="success__item-title"
                                            v-text="item.title"
                                        />
                                        <div
                                            class="success__item-about"
                                            v-text="item.text"
                                        />
                                        <div
                                            class="success__item-more"
                                            @click="openSuccessModal(index)"
                                        >
                                            Читать дальше
                                        </div>
                                        <div
                                            class="success__item-name"
                                            v-text="item.name"
                                        />
                                    </div>
                                </div>
                            </template>
                        </base-carousel>
                    </div>
                </div>
                <div class="section__button success__button">
                    <w-button class="j-reg_init">
                        Хочу так же
                    </w-button>
                </div>
            </div>
        </div>
        <div class="section section--grey how">
            <div class="section__inner">
                <div class="section__title section__title--black">
                    Как это работает
                </div>
                <div class="how__wrapper">
                    <div
                        class="how__title"
                        v-text="profitExample.title"
                    />
                    <div class="how__tabs">
                        <w-switch
                            :items="switchPerson"
                            @change="togglePersonType"
                        />
                    </div>
                    <div class="how__content">
                        <div class="how__about">
                            <ol v-if="personType === 'client'">
                                <li
                                    v-for="(item, index) in profitExample.client"
                                    class="how__about-item"
                                    v-text="item"
                                    :key="index"
                                />
                            </ol>
                            <ol v-else>
                                <li
                                    v-for="(item, index) in profitExample.me"
                                    class="how__about-item"
                                    v-text="item"
                                    :key="index"
                                />
                            </ol>
                        </div>
                        <div class="how__profit profit">
                            <div
                                class="profit__title"
                                v-text="personType === 'client' ? 'заработаете' : 'экономите'"
                            />
                            <div class="profit__text">
                                <div class="profit__icon-wrapper">
                                    <svg
                                        slot="icon"
                                        class="icon profit__icon"
                                    >
                                        <use xlink:href="#wallet" />
                                    </svg>
                                </div>
                                {{
                                    personType === 'client'
                                        ? profitExample.clientProfit
                                        : profitExample.meProfit
                                }}
                            </div>
                            <div class="section__button profit__button">
                                <w-button class="j-reg_init">
                                    Понятно, хочу начать
                                </w-button>
                            </div>
                        </div>
                    </div>
                    <div class="section__button">
                        <w-button
                            outline
                            @click="changeProfitExample"
                        >
                            Другой пример
                        </w-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="section workle" id="about">
            <div class="section__inner">
                <div class="workle__title section__title section__title--black">
                    Что нужно знать о Workle
                </div>
                <div class="workle__content">
                    <div class="workle__picture" />
                    <div class="workle__about">
                        <div class="workle__subtitle">
                            Workle – сервис официальной онлайн-работы
                        </div>
                        <p>
                            Благодаря нам работа через интернет в популярных бизнес-направлениях стала
                            доступна каждому. Здесь любой совершеннолетний гражданин РФ может построить
                            онлайн-карьеру и зарабатывать деньги. Образование и опыт работы не
                            требуются.
                        </p>
                        <p>
                            Вы можете иметь стабильный доход, не выходя из дома. Где партнеры будут
                            платить столько же, сколько рядовому сотруднику в офисе, а компьютер и
                            интернет – готовое рабочее место. Регистрируясь на сайте, вы получаете
                            официальные гарантии и выплаты.
                        </p>
                    </div>
                </div>
                <div class="workle__partners">
                    <div
                        v-for="item in partners"
                        class="workle__partner"
                        :key="item.title"
                    >
                        <img :src="item">
                    </div>
                </div>
            </div>
        </div>
        <div class="section join">
            <div class="section__inner">
                <div class="join__title section__title section__title--white">
                    Начните работать в лучшей<br />интернет-компании страны
                </div>
                <div class="join__about">
                    Хотите стать фрилансером, начать свой бизнес и ни от кого не зависеть?<br />Мы
                    поможем осуществить вашу мечту.<br />Workle – лучший способ заработка в интернете с 2011
                    года!
                </div>
                <div class="section__button join__button">
                    <button class="j-reg_init button-item button-item--white">
                        Присоединиться
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import { WButton, WModal, WSwitch } from 'Utils/ui'
    import BaseCarousel from 'Components/components/BaseCarousel.vue'
    import HomeSuccessModal from 'Components/components/HomeSuccessModal.vue'
    import HomeCandidateModal from 'Components/components/HomeCandidateModal.vue'
    import WalletIcon from 'Components/assets/wallet.svg?component'
    import WorkersIcon from '!!svg-inline-loader!Assets/sprites/home-workers-icons.svg'
    import OpportunitiesIcon from '!!svg-inline-loader!Assets/sprites/home-opportunities-icons.svg'
    import ArrowIcon from 'Components/assets/arrow.svg?component'

    export default {
        name: 'HomeContent',
        components: {
            BaseCarousel,
            WalletIcon,
            WButton,
            WModal,
            WSwitch,
            ArrowIcon
        },
        computed: {
            financeProduct () {
                return this.productItem[0]
            },
            profitExample () {
                return this.howWorks[this.selectedExample]
            },
            counters () {
                return [
                    {
                        text: 'Пользователей на Workle',
                        value: this.displayUsersValue
                    },
                    {
                        text: 'Cделали продаж на сумму',
                        value: this.displaySellsValue + ' ₽'
                    },
                    {
                        text: 'Заработали',
                        value: this.displayProfitValue + ' ₽'
                    }
                ]
            },
        },
        filters: {
            toRoublesLocale (value) {
                if (!value) return ''

                return value.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ').trim()
            }
        },
        data () {
            return {
                WorkersIcon,
                OpportunitiesIcon,
                selectedExample: 0,
                usersValue: 0,
                sellsValue: 0,
                profitValue: 0,
                displayUsersValue: 0,
                displaySellsValue: 0,
                displayProfitValue: 0,
                usersValueInterval: false,
                sellsValueInterval: false,
                profitValueInterval: false,
                personType: 'client',
                switchPerson: [ { text: 'для клиента', id: 0 }, { text: 'для меня', id: 1 } ],
                candidates: [
                    {
                        alias: 'worker',
                        title: "Любому, кто ищет работу",
                        about: "Временная или постоянная занятость с гарантиями и выплатами, откуда вас никогда не уволят",
                        modalAbout: "Работайте удаленно, уделяя этому столько времени, сколько захочется. Можно продолжить поиски работы и получать пассивный доход. Или заняться активными продажами и иметь стабильный официальный доход, не выходя из дома.",
                        profit: [ 'Свободный график работы', 'Корпоративное онлайн-обучение', 'Несколько направлений для заработка', 'Возможность карьерного роста', 'Помощь и поддержка на всех этапах' ],
                    },
                    {
                        alias: 'document',
                        title: "Работникам по найму",
                        about: "Еще один официальный источник дохода в свободное время без ущерба основной работе (можно совмещать)",
                        modalAbout: "Перед вами не встанет вопрос, какую работу выбрать. При регистрации с вами будет заключен гражданско-правовой договор в виде публичной оферты. Это значит, что вы не нарушите трудовое законодательство, совмещая Workle с текущим местом работы.",
                        profit: [ 'Официальный источник дохода', 'Круглосуточный доступ к сервису', 'Выбор из нескольких бизнес-направлений', 'Опыт работы не требуется', 'Привилегии при оформлении себе или родным' ],
                    },
                    {
                        alias: 'location',
                        title: "Блогерам",
                        about: "Заработок на продвижении и оформлении различных услуг через личные рекомендации в блоге",
                        modalAbout: "Отзывы, рекомендации и полезный контент – это то, что пользуется огромной популярностью и спросом среди интернет-аудитории. Делитесь информацией, давайте рекомендации и получайте за это не только лояльных подписчиков, но и реальные деньги.",
                        profit: [ 'Большой выбор предложений', 'Широкая география оформления', 'Прямые ссылки для продвижения в блогах', 'Рекламные материалы от партнеров', 'Официальные выплаты и 2-НДФЛ' ],
                    },
                    {
                        alias: 'group',
                        title: "Лидерам мнений",
                        about: "Независимое мнение и работа с аудиторией, как источник дохода в соцсетях (Youtube и др.)",
                        modalAbout: "Вам доверяют, к вашему мнению прислушиваются, а значит вы можете смело рекомендовать полезные услуги людям, которые вас окружают, и зарабатывать на этом как физическое лицо или индивидуальный предприниматель.",
                        profit: [ 'Известные компании-партнеры России', 'Востребованные товары и услуги', 'Партнерская программа с высоким %', 'Прямые ссылки и ссылки для соцсетей', 'Привилегии при оформлении себе или родным' ],
                    },
                    {
                        alias: 'runner',
                        title: "Веб-мастерам",
                        about: "Большой выбор офферов с реферальными ссылками для привлечения и монетизации целевого трафика",
                        modalAbout: "Партнерская программа Workle не имеет аналогов. Здесь представлено огромное количество предложений для арбитража: от банковских и страховых услуг до рекрутинга и популярных интернет-магазинов. Выбирайте любой оффер и получайте пассивный доход с каждой сделки.",
                        profit: [ 'Реферальные ссылки для продвижения', 'Полностью официальный доход', 'Быстрые выплаты на карту или счет', 'Разные виды допустимого трафика', 'Широкая география оформления' ],
                    },
                    {
                        alias: 'lounge',
                        title: "Агентским сетям",
                        about: "Расширение имеющейся продуктовой линейки, сотрудничество с компаниями на более выгодных условиях",
                        modalAbout: "Для вас предусмотрены эксклюзивные условия с партнерами и большой выбор предложений. Масштабируйте свой бизнес, расширив список оформляемых услуг. Благодаря профессиональным онлайн-инструментам вы будете тратить на работу меньше времени, а зарабатывать больше.",
                        profit: [ 'Большой выбор услуг и партнеров', 'Особые условия по доходу', 'Онлайн-кабинет для работы с субагентами', 'Сотрудничество с ИП или физлицами', 'Быстрые выплаты и вывод в любой день' ],
                    },
                ],
                productItem: [
                    {
                        picture: '/s3storage/commonimages/ef981319-437b-4a5b-9376-1d3e6b983f99.png',
                        title: "Финансы",
                        categories: [
                            "Дебетовые карты",
                            "Кредитные карты",
                            "Карты рассрочки",
                            "РКО/Регистрация ИП",
                            "Микрозаймы",
                            "Потребительские кредиты",
                            "Ипотека"
                        ],
                        profit: '10 000 руб. за сделку'
                    },
                    {
                        picture: '/s3storage/commonimages/fcc49332-d783-4a10-a4ea-74100d07b714.png',
                        title: "Туризм",
                        categories: [ "Пакетные туры", "Авиабилеты", "Отели", "Трансферы" ],
                        profit: '12% за сделку'
                    },
                    {
                        picture: '/s3storage/commonimages/917121f8-b52a-4ddf-939e-887c69448a2f.png',
                        title: "Страхование",
                        categories: [ "ОСАГО", "КАСКО", "ВЗР", "Недвижимость" ],
                        profit: '44% за сделку'
                    },
                    {
                        picture: '/s3storage/commonimages/4288da8c-bdd1-4718-92d9-a5e1a02d24dc.png',
                        title: "Подбор персонала",
                        categories: [ "Водители", "Курьеры", "Консультанты", "Продавцы" ],
                        profit: '6 000 руб. за сделку'
                    },
                    {
                        picture: '/s3storage/commonimages/3b174faf-90fc-4fe2-abcb-ce51cbcf527d.png',
                        title: "Магазины-партнеры",
                        categories: [ "Aliexpress", "Сервис ivi", "ЛитРес", "КупиКупон" ],
                        profit: '10% за сделку'
                    },
                ],
                steps: [ 'Пройдите простую<br />регистрацию на сайте', 'Ознакомьтесь с предложениями компаний-партнеров', 'Оформите услугу себе или знакомым людям', 'Получите первые деньги и выведите на карту' ],
                opportunities: [
                    {
                        alias: 'wallet',
                        title: 'Онлайн-работа для всех и каждого',
                        about: 'Workle подходит не только профессионалам, но и людям без опыта. Вам больше не придется рассылать резюме, проходить собеседование и испытательный срок. Зарабатывать деньги в интернете можно с первого дня! Просто зарегистрируйтесь и приступайте к работе.',
                    },
                    {
                        alias: 'checkMark',
                        title: 'На Workle у вас будет все',
                        about: '<ul><li>Официальная работа в интернете (заключение договора)</li><li>Справка 2-НДФЛ для подтверждения дохода (по запросу)</li><li>Надежные партнеры по всей России</li><li>Выбор собственного графика работы в интернете</li><li>Привилегии при совершении покупок для себя и родных</li></ul>',
                    },
                    {
                        alias: 'gear',
                        title: 'Как работает система',
                        about: 'Компании готовы платить за привлечение клиентов. Они размещают на сайте свои предложения и назначают вознаграждение за их успешное продвижение. Вы можете воспользоваться этим и зарабатывать столько, сколько захотите, даже не выходя из дома',
                    },
                    {
                        alias: 'heart',
                        title: 'Вы в надежных руках',
                        about: 'Мы являемся официальным резидентом «Сколково» и производим все налоговые отчисления и взносы в Пенсионный фонд РФ. Вывод вашего заработка происходит на банковские реквизиты (вашу карту или счет), что делает процесс надежным и простым.',
                    },
                ],
                success: [
                    {
                        title: 'Узнал о сервисе от знакомого',
                        text: 'После регистрации проанализировал, какие предложения имеются. Первого клиента нашел сразу – один знакомый невзначай сказал, что нужна небольшая сумма. Занимать ни у кого не стал, а сам сделал запрос в МФО, но получил отказ и не поймет почему. Я сказал, что главное выбрать надежною компанию и корректно заполнить заявление. На следующий день встретился с ним и отправил заявку. Нам одобрили нужную сумму и перевели на его банковскую карту. Спустя пару дней по его рекомендации ко мне обратились другие люди. Моя постоянная закономерность успеха, которую я подтвердил лично: «Изучил услугу – помог клиенту – получил результат».',
                        name: 'Леонид Буряк',
                    },
                    {
                        title: 'Уже пять лет на удаленке',
                        text: 'Я маркетолог, заработок меня вполне устраивал, пока не началась шумиха с пандемией. В один момент встало все. И я ушел в «отпуск». Все мои партнеры тоже. Короче говоря, остался временно без работы. Сидеть на месте не хотелось – стал искать возможности заработать. Когда-то я начинал с арбитража, сидел в партнерках (товарка), опыт имелся. Этот сайт был единственный вариант заработать денег на самоизоляции. От и до посмотрел, начитался кучу отзывов, в том числе и негативных. Увидел, что есть офферы, которых нет в некоторых партнерках. Решил протестировать. Кинул минимальный трафик, пару дней обрабатывал заявки и решил подождать. Через неделю в мой кабинет начислили в районе 2 000 руб. Появилось доверие, что начисляют, мифы о негативных отзывах развеялись в прах. Самое приятное, что в один день я был на первом месте среди лидеров на Доске почета. В этот момент я понял, что Workle станет частью моей жизни на долгое время.',
                        name: 'Алексей Рудь',
                    },
                    {
                        title: 'Название Workle я не встречала ранее',
                        text: 'Но прочитав десяток отзывов, решила поверить, что здесь все-таки можно заработать. Как получила первые деньги? Я искала подарок мужу. Он очень хотел цепочку в приближающийся Новый год. Обойдя несколько магазинов, я нашла ту единственную и неповторимую цепь, но, к сожалению или к счастью, не по карману для меня. Вежливый продавец предложила оформить карту рассрочки «Халва», всячески ее расхваливая. И тут я вспомнила про сайт, на котором недавно зарегистрировалась. Я оформила карту без обучения. На следующий день позвонил сотрудник банка. Все произошло так быстро, и заветная карта была у меня в руках. Вскоре мне пришло вознаграждения за данную операцию, и я поняла, что Workle действительно приносит доход.',
                        name: 'Наталья Игнатова',
                    },
                    {
                        title: 'Заработала 900 руб. за 10 минут',
                        text: 'Давно искала работу в интернете в качестве подработки. И поверьте, попадалась не раз на мошенников: заработали они, а не я. Уже отчаявшись в успехе, я и не думала о повторных попытках. Но в силу моей наивности или упертости решила попробовать еще раз. Прошла по ссылке-приглашению от «Яндекс.Работа». Прошло буквально пару дней, и я совершила свою первую сделку – оформила себе полис «Коронавирус.НЕТ», а затем еще две страховки коллегам по работе. И заработала 900 руб. за 10 минут, класс!',
                        name: 'Татьяна Заруднева',
                    },
                    {
                        title: 'Нашла по запросу «удаленная работа»',
                        text: 'На тот момент я уже полтора года была в декрете. Хотелось каких-то ощутимых достижений, отвлечься от «дня сурка». Зарегистрировалась, посмотрела сайт. Увидела, что много обучающих видео. Это было в мае. Я находилась в деревне с медленным интернетом. Решила отложить… Ну, как обычно, это бывает. В итоге более активно я начала заниматься осенью. Выбрала страхование ОСАГО – поскольку это обязательный полис и авто есть у многих знакомых. Разместила информацию на своей страничке ВК. И уже скоро ко мне обратились несколько одноклассников. Сейчас уверенно предлагаю услуги незнакомым людям – это совсем не страшно, как казалось в начале пути!',
                        name: 'Наталья Жигалина',
                    },
                    {
                        title: 'Искала подработку, выйдя на пенсию',
                        text: 'Чем я только не занималась: рассылки, реклама, прошла уйму мастер-классов и тренингов, но все не то. Однажды увидела рекламу: красивая девушка сидит с ноутбуком на пляже. Написано Workle. Я подумала, что очередной развод, и пошла дальше. Но этой ночью увидела сон, что тоже сижу на пляже с ноутбуком и в красивой шляпе. Работаю. Проснувшись, меня охватило какое-то трепетное желание срочно найти эту рекламу. Все-таки нашла. А теперь, не поверите, открыла мини-офис. Надеюсь, что у меня все получится. Спасибо Workle за предоставленную возможность иметь подработку на пенсии.',
                        name: 'Тамара Широкова',
                    },
                    {
                        title: 'Наткнулся на Workle в Telegram',
                        text: 'Как раз во время пандемии. Там рассказывалось о Workle-старт. Решил попробовать, зарегистрировался и начал обучение, попутно разбираясь c сайтом. Решил, если я не сумею получить бонус, значит, эта работа не для меня, потому что я полный ноль в интернет-заработках. За месяц бонус был отработан. Сейчас изучаю интернет-продвижение партнерских программ и вижу в этом перспективу на Workle.',
                        name: 'Антон Трегубенко',
                    },
                    {
                        title: 'Как я зарабатываю в декрете и после него',
                        text: 'Я мама прекрасной девочки, которая, придя в сад, тут же заболевает. Поэтому хорошо представляю, что такое долгое время сидеть дома, когда не хватает денег и нет возможности реализовать свои способности. Большинство женщин, которых я знаю, зарабатывают, находясь в декрете. Я сама первую подработку нашла в интернете, и это был Workle. Со временем подработка в свободное время превратилась в полноценную удаленную работу «на себя». Она приносит хороший доход и дает возможность строить свой день по собственному усмотрению, не тратя время на дорогу в офис. Да и о возвращении в офис я даже думать не хочу!',
                        name: 'Виолетта Лето',
                    },
                    {
                        title: 'Вспомнила об онлайн-кабинете',
                        text: 'Мое знакомство с Workle произошло шесть с половиной лет назад. На тот момент мое финансовое положение оставляло желать лучшего. Ипотека, двое детей, куча кредитов и одна зарплата мужа на всех… Поэтому работала в интернете – писала статьи. Но это такие копейки. Решила найти более достойную работу с высокой оплатой и забрела на этот сайт. Однако дальше регистрации дело не пошло. За это время сменила работу кассира на руководителя Центра Выдачи Займов. Когда пошла в декрет, вспомнила о своем онлайн-кабинете. Вошла в него и теперь-то я поняла, что все легко, так как все это время работала с кредитами и займами, а также с ОСАГО. Это мое ВСЕ!',
                        name: 'Юлия Хайрулина',
                    },
                    {
                        title: 'Первые 70 руб. ощущались как 70 000',
                        text: 'Я пытался сотрудничать с онлайн-кинотеатром ivi.ru, но они сказали, что работают только с юридическими лицами. Посоветовали несколько сайтов для физлиц, в том числе workle.ru. Зарегистрировался, посмотрел инструкцию и предложил другу купить подписку на ivi.ru по моей реферальной ссылке. Он оформил, а я ждал, пока мне подтвердят сделку. Эти первые 70 руб. на Workle ощущались как 70 000 руб. И теперь благодаря данному сервису я могу предлагать читателям в моем киноблоге подписку на онлайн-кинотеатр.',
                        name: 'Дмитрий Бондаренко',
                    },
                    {
                        title: '10 трудоустроенных в карантин',
                        text: 'Или как я сделала мир лучше во время пандемии, заработав свои первые 3 000 руб. на Workle. Я просматривала на карантинном сайте «Все онлайн» разную информацию и случайно увидела от «Яндекс.Таланты» информацию об акции – 300 бесплатных откликов на ваши вакансии. Так как уже знала про Workle и профессию «Подбор персонала», путем нехитрых умозаключений я пришла к выводу: вот он – бесплатный источник для привлечения первых клиентов, нельзя упускать шанс! Самой востребованной показалась вакансия «Сборщик заказов» от Сбермаркет. Разместила информацию о ней и получила за 2 суток триста откликов, обработала заявки, оформила их на Workle. Началась томительная пора ожидания, не верилось, что здесь можно получить заработок. Но в один прекрасный день пришли первые деньги за работу. Теперь мотивация у меня огромная, надеюсь, сервис станет основным источником дохода.',
                        name: 'Ирина Козырева',
                    }
                ],
                partners: [
                    '/s3storage/commonimages/2635a147-ecca-4148-8497-ba3b63abbc80.jpg',
                    '/s3storage/commonimages/ef76485a-24e2-4320-ba26-83e293b0dd06.jpg',
                    '/s3storage/commonimages/5a6e8117-c5eb-4cdc-b681-b746c1b82989.jpg',
                    '/s3storage/commonimages/81d3bfcb-d237-4486-b176-ee76d6f1624e.jpg',
                    '/s3storage/commonimages/f20a90fb-e803-45df-a03e-6c72ac9b9594.jpg',
                    '/s3storage/commonimages/348213d9-aafd-411c-ae6e-00beaf8f29a3.jpg',
                    '/s3storage/commonimages/07d0b31a-1730-44da-a1c0-04dd4a0c5850.jpg',
                    '/s3storage/commonimages/2138b6e0-56ca-4017-ac21-2114758a21be.jpg',
                    '/s3storage/commonimages/ff480f3b-d681-4726-8081-3e202dbad9e6.jpg',
                    '/s3storage/commonimages/28e41922-30e4-4c34-9b58-d135e50d24aa.jpg',
                    '/s3storage/commonimages/387efc2d-084b-4b69-9bce-ea7b6e3c5d44.jpg',
                    '/s3storage/commonimages/5a73e3b6-128a-4f7b-9e4d-5b81aa0a29ec.jpg',
                ],
                howWorks: [
                    {
                        title: 'Если нужен турпакет',
                        client: [ 'Подберите тур на сайте, согласуйте с клиентом и забронируйте', 'Получите комиссию с продажи пакетного тура', 'Совершайте сделки на workle.ru регулярно и зарабатывайте' ],
                        clientProfit: '30 000 – 70 000 руб./мес.',
                        me: [ 'Выберите нужный тур на сайте и забронируйте его', 'Получите самые выгодные условия при оформлении себе', 'Отдыхая регулярно, к примеру, 2 раза в год, вы экономите' ],
                        meProfit: '20 000 – 80 000 руб.'
                    },
                    {
                        title: 'Если нужен полис КАСКО',
                        client: [ 'Рассчитайте стоимость полиса на сайте и оформите его клиенту', 'Получите комиссию с продажи полиса', 'Совершайте сделки на workle.ru регулярно и зарабатывайте' ],
                        clientProfit: '35 000 – 80 000 руб./мес.,',
                        me: [ 'Рассчитайте стоимость полиса на сайте и оформите его', 'Получите самые выгодные условия при оформлении себе', 'Экономьте на оформлении полиса КАСКО' ],
                        meProfit: '5 000 – 10 000 руб.'
                    },
                    {
                        title: 'Если нужна кредитная карта',
                        client: [ 'Подберите клиенту карту и оформите заявку в банк', 'Получите комиссию за выданную карту', 'Совершайте сделки на workle.ru регулярно и зарабатывайте' ],
                        clientProfit: '15 000 – 45 000 руб./мес.',
                        me: [ 'Подберите кредитную карту и оформите заявку в банк', 'Получите комиссию за выданную карту', 'Сэкономьте для собственного бюджета' ],
                        meProfit: '1 000 – 2 000 руб.'
                    },
                    {
                        title: 'Если нужен кредит',
                        client: [ 'Подберите клиенту программу кредитования и оформите заявку', 'Получите комиссию за выданный кредит', 'Совершайте сделки на workle.ru регулярно и зарабатывайте' ],
                        clientProfit: '20 000 – 84 000 руб./мес.',
                        me: [ 'Подберите себе программу кредитования и оформите заявку', 'Получите комиссию за выданный кредит', 'Сэкономьте для собственного бюджета' ],
                        meProfit: '4 000 – 5 000 руб.'
                    },
                    {
                        title: 'Если нужен отель',
                        client: [ 'Подберите клиенту отель, согласуйте и забронируйте номер', 'Получите комиссию с продажи отеля', 'Совершайте сделки на workle.ru регулярно и зарабатывайте' ],
                        clientProfit: '15 000 – 31 000 руб./мес.',
                        me: [ 'Выберите отель и забронируйте в нем номер', 'Получите для себя самые выгодные условия', 'Отдыхая всей семьей, к примеру, 2 раза в год, экономьте' ],
                        meProfit: '10 000 – 30 000 руб.'
                    },
                    {
                        title: 'Если нужна работа',
                        client: [ 'Предложите клиенту подходящую вакансию', 'Отправьте заявку в компанию-партнер', 'Получайте деньги за трудоустроенных кандидатов' ],
                        clientProfit: '35 000 – 100 000 руб./мес',
                        me: [ 'Подберите себе подходящую вакансию', 'Отправьте заявку в компанию-партнер', 'Получите работу и деньги за трудоустройство' ],
                        meProfit: '1 000 – 2 000 руб.'
                    }
                ],
                partnerCompanies: [
                    '/s3storage/commonfiles/3945b047-e3d3-4b41-9071-1c70826ab95a.png',
                    '/s3storage/commonimages/f5c30eb3-78c0-4a43-b01f-0857371ee252.jpg',
                    '/s3storage/commonimages/8ef9cbbc-8a9b-4998-8569-ed3468fda9c4.jpg',
                    '/s3storage/commonimages/3c3852fe-b6e8-427e-b900-a4b24006f18f.jpg',
                    '/s3storage/commonimages/06590948-0ac8-493f-b7ea-c1fc9d5a03ee.jpg'
                ]
            }
        },
        mounted () {
            this.calculateValues()

            window.setInterval(this.calculateValues, 60000)
        },
        watch: {
            usersValue () {
                clearInterval(this.usersValueInterval)

                if (this.usersValue === this.displayUsersValue) {
                    return undefined
                }

                this.usersValueInterval = window.setInterval(() => {
                    this.displayUsersValue = this.setValueTimeout(this.usersValue, this.displayUsersValue, 10)
                }, 20)
            },
            sellsValue () {
                clearInterval(this.sellsValueInterval)

                if (this.sellsValue === this.displaySellsValue) {
                    return undefined
                }

                this.sellsValueInterval = window.setInterval(() => {
                    this.displaySellsValue = this.setValueTimeout(this.sellsValue, this.displaySellsValue, 6)
                }, 20)
            },
            profitValue () {
                clearInterval(this.profitValueInterval)

                if (this.profitValue === this.displayProfitValue) {
                    return undefined
                }

                this.profitValueInterval = window.setInterval(() => {
                    this.displayProfitValue = this.setValueTimeout(this.profitValue, this.displayProfitValue, 8)
                }, 20)
            }
        },
        methods: {
            setValueTimeout (totalAmount, displayAmount, speed) {
                if (displayAmount !== totalAmount) {
                    let change = (totalAmount - displayAmount) / speed

                    change = change >= 0 ? Math.ceil(change) : Math.floor(change)
                
                    displayAmount = displayAmount + change
                }

                return displayAmount
            },
            calculateValues () {
                const startedDate = localStorage.getItem('currentDate') || new Date('03.06.2020').totalMinutes()
                const currentDate = new Date().totalMinutes().toFixed()
                const initialUserValue = 2843134
                const initialSellsValue = 22859487585
                const initialProfitValue = 1620660213

                localStorage.setItem('currentDate', currentDate)
4
                const baseUserValue = parseInt(localStorage.getItem('userValue')) || initialUserValue
                const baseSellsValue = parseInt(localStorage.getItem('sellsValue')) || initialSellsValue
                const baseProfitValue = parseInt(localStorage.getItem('profitValue')) || initialProfitValue

                this.usersValue = baseUserValue + (currentDate - startedDate).toFixed() * 1
                this.sellsValue = baseSellsValue + (currentDate - startedDate).toFixed() * 347
                this.profitValue = baseProfitValue + (currentDate - startedDate).toFixed() * 208

                localStorage.setItem('userValue', this.usersValue)
                localStorage.setItem('sellsValue', this.sellsValue)
                localStorage.setItem('profitValue', this.profitValue)
            },
            openCandidateModal (index) {
                const { alias, title, about, modalAbout, profit } = this.candidates[index]
                const CandidateClass = Vue.extend(HomeCandidateModal)
                const ModalClass = Vue.extend(WModal)
                const CandidateInstance = new CandidateClass({
                    propsData: {
                        title,
                        modalAbout,
                        profit
                    }
                })

                this.modal = new ModalClass({
                    propsData: {
                        size: 'large'
                    }
                })

                this.modal.$mount()
                this.modal.$slots.body = [CandidateInstance.$mount()._vnode]
            },
            openSuccessModal (index) {
                const { title, text, name } = this.success[index]
                const SuccessClass = Vue.extend(HomeSuccessModal)
                const ModalClass = Vue.extend(WModal)
                const SuccessInstance = new SuccessClass({
                    propsData: {
                        title,
                        text,
                        name
                    }
                })

                this.modal = new ModalClass({
                    propsData: {
                        size: 'large'
                    }
                })

                this.modal.$mount()
                this.modal.$slots.body = [SuccessInstance.$mount()._vnode]
            },
            togglePersonType () {
                this.personType = this.personType === 'client' ? 'me' : 'client'
            },
            changeProfitExample () {
                this.selectedExample < 5 ? this.selectedExample += 1 : this.selectedExample = 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .switch__text {
        padding: 14px 0;
    }

    .icon {
        width: 60px;
        height: 60px;
        background: #e4f6fd;;
        border-radius: 50%;
        fill: #0ca9ef;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-item {
        width: 100%;
        height: 50px;
        border-radius: 100px;
        transition: box-shadow .2s ease-in-out, color .2s ease-in-out, background .2s ease-in-out;
        font-weight: bold;
        font-size: 1.125rem;

        @media (min-width: 768px) {
            width: 240px;
            align-self: flex-end;
        }

        &--white {
            background: #fff;
            color: #0ca9ef;

            &:hover {
                box-shadow: 0 4px 30px rgba(0, 0, 0, .16);
            }
        }

        &--transparent {
            border: 2px solid #fff;
            box-sizing: border-box;
            color: #fff;
            background-color: transparent;
            margin-top: 10px;

            &:hover {
                box-shadow: 0 4px 30px rgba(0, 0, 0, .16);
                color: #0ca9ef;
                background: #fff;
            }

            @media (min-width: 768px) {
                margin-top: 0;
            }
        }
    }

    .section__button {
        display: flex;
        justify-content: center;
        margin: 30px auto 0;
        width: 100%;
        height: 50px;
        font-size: 18px;

        @media (min-width: 480px) {
            padding: 0 72px;
        }

        @media (min-width: 768px) {
            margin-top: 40px;
            width: 240px;
            padding: 0;
        }
    }

    .buttons-group {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
            width: 492px;
            flex-direction: row;
            justify-content: space-between;
            margin: auto;
        }

        @media (min-width: 1024px) {
            width: 510px;
            margin: 0;
        }
    }

    .section {
        padding: 0 20px 60px;

        @media (min-width: 480px) {
            padding: 0 72px 60px;
        }

        @media (min-width: 768px) {
            padding: 0 20px 80px;
        }

        &--grey {
            background: #f1f3f8;
        }

        &__inner {
            max-width: 1170px;
            margin: auto;
            position: relative;
        }

        &__title {
            font-weight: bold;
            font-size: 28px;
            line-height: 100%;
            text-align: center;
            padding: 60px 0 30px;

            @media (min-width: 768px) {
                padding: 80px 0px 40px;
                font-size: 34px;
                line-height: 130%;
                margin: auto;
            }

            @media (min-width: 1024px) {
                font-size: 34px;
            }

            &--black {
                color: #333;
            }

            &--white {
                color: #fff;
            }
        }
    }

    .main {
        margin-top: 54px;
        padding: 50px 20px 0;
        background: linear-gradient(180deg, #1DB6FA 0%, #0CA9EF 100%);

        @media (min-width: 1024px) {
            margin-top: 80px;
        }

        &__title {
            font-size: 36px;
            line-height: 110%;
            color: #fff;
            font-weight: bold;
            text-align: center;

            @media (min-width: 768px) {
                font-size: 46px;
                line-height: 100%;
            }

            @media (min-width: 1024px) {
                text-align: left;
            }
        }

        &__about {
            padding-top: 20px;
            font-size: 18px;
            line-height: 100%;
            color: #fff;
            width: 290px;
            margin: 0 auto 14px;
            padding-left: 20px;

            @media (min-width: 768px) {
                display: flex;
                width: 493px;
                padding: 30px 0 38px;
            }

            @media (min-width: 1024px) {
                padding-left: 0;
                margin-left: 0;
            }
        }

        &__about-item {
            padding-bottom: 10px;
            list-style-type: initial;

            @media (min-width: 768px) {
                list-style: none;
                border-right: 1px solid rgba(255, 255, 255, .3);
                margin-right: 15px;
                padding-right: 10px;
                
            }

            &:nth-child(3) {
                margin: 0;
                border: none;
            }
        }

        &__inner {
            @media (min-width: 1024px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;   
                height: 483px;
            }

            @media (min-width: 1200px) {
                height: 476px;
            }
        }

        &__left {
            @media (min-width: 1024px) {
                width: 50%;
                padding-top: 30px;
            }
        }

        &__picture {
            background-image: url(/s3storage/commonimages/b03e52db-9801-4bda-b8e1-21df9f726a83.png);
            width: 300px;
            height: 239px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;
            margin: auto;

            @media (min-width: 768px) {
                width: 360px;
                height: 287px;
            }

            @media (min-width: 1024px) {
                position: absolute;
                right: -226px;
                top: -85px;
                width: 655px;
                height: 568px;
            }

            @media (min-width: 1200px) {
                right: -45px;
                top: -92px;
            }
        }
    }

    .counters {
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 0;

        &__inner {
            padding-top: 6px;
            padding-bottom: 30px;

            @media (min-width: 768px) {
                display: flex;
                width: 100%;
            }

            @media (min-width: 1024px) {
                max-width: 1170px;
            }
        }

        &__item {
            padding-top: 24px;

            @media (min-width: 768px) {
                width: 100%;
            }
        }

        &__item-value {
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            text-align: center;
            color: #0ca9ef;
        } 

        &__item-about {
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: #333;
            padding-top: 10px;
        }
    }

    .candidates__group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .candidate {
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 30px 20px;
        transition: box-shadow .3s;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            width: calc(50% - 15px);
            margin-bottom: 30px;
            padding: 30px;
        }

        @media (min-width: 1024px) {
            width: calc(33% - 15px);
        }

        &:nth-child(5) {
            @media (min-width: 1024px) {
                margin-bottom: 0px;
            }
        }

        &:nth-child(6) {
            @media (min-width: 768px) {
                margin-bottom: 0px;
            }
        }

        &:nth-child(7) {
            margin-bottom: 0px;
        }

        &:hover {
            box-shadow: 0 5px 24px rgba(7, 68, 96, .15);
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            padding: 20px 0 10px;

            @media (min-width: 768px) {
                font-size: 20px;
                padding-top: 24px;
            }
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
        }
    }

    .products {
        display: flex;

        &__title {
            padding-bottom: 14px;
        }

        &__about {
            font-style: normal;
            font-size: 18px;
            line-height: 130%;
            text-align: center;
            color: #333;
            padding-bottom: 20px;
        }

        &__group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            @media (min-width: 1024px) {
                flex-direction: row;
                flex-wrap: wrap;
                height: 450px;
                max-width: 984px;
            }

            @media (min-width: 1440px) {
                max-width: 1170px;
            }

            &--left {
                align-items: flex-start;
                flex-direction: column;
            }

            &--right {
                @media (min-width: 1024px) {
                    width: 66%;
                }
            }
        }
    }

    .product {
        background: #fff;
        border-radius: 8px;
        padding: 30px 20px;
        margin-bottom: 20px;
        position: relative;
        transition: box-shadow .3s;
        width: 100%;

        @media (min-width: 375px) {
            width: 100%;
        }

        @media (min-width: 480px) {
            width: 335px;
        }

        @media (min-width: 1024px) {
            width: 312px;
            height: 210px;
            padding: 30px;
            margin-bottom: 30px;
        }

        @media (min-width: 1440px) {
            width: 370px;
        }

        &:hover {
            box-shadow: 0 5px 24px rgba(7, 68, 96, .15)
        }

        &--big {
            flex: 1;

            @media (min-width: 1024px) {
                margin-bottom: 0;
            }
        }

        &__picture {
            position: absolute;
            right: 0;
            top: 0;
        }

        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
            padding-bottom: 16px;
            position: relative;

            @media (min-width: 1024px) {
                padding-bottom: 24px;
            }
        }

        &__categories {
            padding-left: 20px;
            font-style: normal;
            font-size: 16px;
            line-height: 100%;

            @media (min-width: 1024px) {
                display: flex;
                flex-wrap: wrap;
            }

            &--big {
                flex-direction: column;
            }
        }

        &__category {
            list-style-position: unset;
            list-style-type: disc;
            padding-bottom: 14px;
            position: relative;
            z-index: 5;

            @media (min-width: 1024px) {
                width: 50%;
            }

            &--wide {
                @media (min-width: 1024px) {
                    width: 100%;
                }
            }
        }

        &__profit {
            font-size: 18px;
            line-height: 100%;

            @media (min-width: 1024px) {
                padding-top: 0;
            }
        }
    }

    .finance-product {
        &__title {
            @media (min-width: 1024px) {
                padding-bottom: 50px;
            }
        }

        &__profit {
            @media (min-width: 1024px) {
                padding-top: 41px;
            }
        }
    }

    .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 18px;

        @media (min-width: 1024px) {
            width: 190px;
            height: 100%;
        }

        @media (min-width: 1440px) {
            width: 270px;
        }

        &__arrow {
            display: block;
            width: 20px;
            transform: rotate(-90deg) scale(1.2);
        }

        &__inner:nth-child(4) {
            .step__arrow-wrapper {
                display: none;
            }
        }

        &__arrow-wrapper {
            display: none;
            height: 57px;
            width: 20px;
            position: relative;
            left: 26px;
            fill: #E8E8E8;
            align-items: center;

            @media (min-width: 1024px) {
                display: flex;
            }

            @media (min-width: 1440px) {
                left: 8px;
            }
        }

        &__inner {
            display: flex;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            height: 530px;
            margin: auto;

            @media (min-width: 1024px) {
                height: 100%;
                width: 984px;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: baseline;
            }

            @media (min-width: 1440px) {
                width: 1170px;
            }
        }

        &__logo {
            font-size: 24px;
            font-weight: bold;
            color: #0ca9ef;
        }

        &__about {
            padding-top: 20px;
        }
    }

    .partners {
        &__slide {
            width: 250px;
            height: 70px;
        }
    }

    .help {
        &__title {
            @media (min-width: 768px) {
                padding-bottom: 70px;
            }
        }

        &__content {
            position: relative;

            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
                padding-bottom: 77px;
            }

            @media (min-width: 1440px) {
                padding-bottom: 101px;
            }
        }

        &__picture {
            background-image: url(/s3storage/commonfiles/e441c82f-9177-418c-8631-c74224889793.png);
            width: 320px;
            height: 264px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: auto;
            padding: 10px 0 20px;

            @media (min-width: 768px) {
                width: 614px;
                height: 571.4px;
                position: absolute;
                right: -250px;
                top: -110px;
                z-index: -10;
            }

            @media (min-width: 1024px) {
                right: -95px;
            }

            @media (min-width: 1440px) {
                right: -35px;
            }
        }
    }

    .help-item {
        &:first-child {
            padding-bottom: 24px;
        }

        &__wrapper {
            @media (min-width: 768px) {
                width: 50%;
            }
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            padding-bottom: 10px;
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
        }
    }

    .opportunities__group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .opportunity {
        background: #fff;
        border-radius: 8px;
        padding: 30px 20px;
        transition: box-shadow .3s;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            width: calc(50% - 15px);
            padding: 30px;
            margin-bottom: 30px;

            &:nth-child(4),
            &:nth-child(5) {
                margin: 0px;
            }
        }

        &:nth-child(5) {
            margin: 0px;
        }

        &:hover {
            box-shadow: 0 5px 24px rgba(7, 68, 96, .15);
        }

        &__title {
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            padding: 16px 0 10px;

            @media (min-width: 768px) {
                font-size: 20px;
            }
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
        }
    }

    .success {
        @media (min-width: 480px) {
            padding: 0 20px 60px;
        }

        &__title {
            padding-bottom: 6px;

            @media (min-width: 768px) {
                padding-bottom: 16px;
            }
        }

        &__item-wrapper {
            padding: 24px;
        }

        &__item {
            border: 1px solid #e8e8e8;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 30px 20px;
            width: 250px;
            transition: box-shadow .3s;

            @media (min-width: 1024px) {
                width: 397px;
            }

            @media (min-width: 1440px) {
                width: 470px;
            }

            &:hover {
                box-shadow: 0 5px 24px rgba(7, 68, 96, .15);
            }
        }

        &__item-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 130%;
            align-items: center;
            padding-bottom: 33px;
            min-height: 79px;

            @media (min-width: 1024px) {
                min-height: auto;
            }
        }

        &__item-about {
            height: 192px;
            font-style: normal;
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 16px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
        }

        &__item-more {
            padding-top: 16px;
            padding-bottom: 16px;
            font-weight: bold;
            font-size: 16px;
            line-height: 100%;
            color: #0ca9ef;
            border-bottom: 1px solid #dadada;
            cursor: pointer;
            z-index: 15;
        }

        &__item-name {
            font-size: 16px;
            line-height: 100%;
            display: flex;
            align-items: center;
            padding-top: 16px;
        }

        &__button {
            margin-top: 6px;

            @media (min-width: 768px) {
                margin-top: 16px;
            }
        }
    }

    .how {
        padding-bottom: 60px;

        @media (min-width: 480px) {
            padding: 0 53px 60px;
        }

        @media (min-width: 768px) {
            padding: 0 20px 80px;
        }

        &__wrapper {
            background: #fff;
            border-radius: 8px;
            padding: 30px 20px;
            max-width: 970px;
            margin: auto;

            @media (min-width: 768px) {
                padding: 30px;
            }

            @media (min-width: 1024px) {
                padding: 40px;
            }
        }

        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
            text-align: center;
        }

        &__content {
            max-width: 500px;
            margin: auto;

            @media (min-width: 768px) {
                display: flex;
                align-items: baseline;
                max-width: unset;
            }
        }

        &__tabs {
            height: 40px;
            width: 280px;
            margin: 16px auto 24px;
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 12px;

            @media (min-width: 768px) {
                padding-right: 30px;
                border-right: 1px solid #e8e8e8;
                border-bottom: none;
                padding-bottom: 0;
                width: 515px;
                height: 178px;
            }
        }

        &__about-item {
            list-style-type: decimal;
        }

        &__profit {
            padding-top: 24px;

            @media (min-width: 768px) {
                padding-left: 25px;
                width: 340px;
            }
        }
    }

    .profit {
        &__title {
            padding-left: 38px;
            padding-bottom: 8px;
            font-size: 14px;
            line-height: 100%;
            color: #979797;
        }

        &__text {
            font-weight: bold;
            font-size: 20px;
            line-height: 100%;
            display: flex;
            align-items: center;
        }

        &__icon-wrapper {
            margin-right: 8px;
            background: #E4F6FD;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__icon {
            width: 30px;
            height: 30px;
        }

        &__button {
            margin-top: 24px;
        }
    }

    .workle {
        padding-bottom: 80px;

        &__subtitle {
            font-weight: bold;
            font-size: 24px;
            line-height: 120%;
            padding: 10px 0 14px;
        }

        &__content {
            padding-top: 10px;

            @media (min-width: 768px) {
                display: flex;
                flex-direction: row;
            }
        }

        &__picture {
            background: url(/s3storage/commonimages/1a7e6369-f011-42a7-8fe7-4de8fd900636.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 272px;

            @media (min-width: 768px) {
                width: 300%;
                height: 280px;
            }

            @media (min-width: 1024px) {
                width: 200%;
                height: 388px;
            }
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 30px;

            @media (min-width: 768px) {
                font-size: 18px;
            }
        }

        &__partners {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__partner {
            filter: grayscale(1) opacity(.5);
            transition: filter .2s ease;
            width: calc(50% - 10px);
            display: flex;
            justify-content: center;

            @media (min-width: 768px) {
                width: calc(16% - 10px)
            }

            &:hover {
                filter: unset;
            }
        }

        p {
            padding: 10px 0;
        }
    }

    .join {
        background: url(/s3storage/commonimages/49b566ef-f8cb-405a-8834-ef01d30bbc14.png),
            linear-gradient(180deg, #1db6fa 0, #0ca9ef 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 60px;

        @media (min-width: 768px) {
            padding-bottom: 101px;
        }

        @media (min-width: 1024px) {
            padding: 31px 0 121px;
            background: url(/s3storage/commonimages/42afe88f-a9b8-4e5b-a19d-abc9e39c4247.png),
                url(/s3storage/commonimages/49b566ef-f8cb-405a-8834-ef01d30bbc14.png),
                linear-gradient(180deg, #1db6fa 0, #0ca9ef 100%);
            background-size: contain, cover, contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #fff;
            padding-bottom: 30px;
        }

        &__button {
            margin-top: 10px;
        }
    }
</style>
