<template>
    <div class="candidate modal-item">
        <div
            class="candidate__title"
            v-text="title"
        />
        <div
            class="candidate__about"
            v-text="modalAbout"
        />
        <div class="candidate__profit-title">Ваши преимущества с Workle:</div>
        <ul class="candidate__profit">
            <li
                v-for="item in profit"
                class="candidate__profit-item"
                :key="item"
                v-text="item"
            />
        </ul>
        <w-button
            class="j-reg_init candidate__button"
            :wide="false"
        >
            Зарегистрироваться
        </w-button>
    </div>
</template>

<script>
    import { WButton } from 'Utils/ui'

    export default {
        name: 'HomeCandidateModal',
        components: {
            WButton
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            modalAbout: {
                type: String,
                default: ''
            },
            profit: {
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .candidate {
        text-align: left;

        &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: .02em;
            padding-bottom: 14px;
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 28px;

            &--bold {
                font-weight: 700;
                font-size: 16px;
                line-height: 130%;
            }
        }

        &__profit-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 100%;
        }

        &__profit {
            list-style-position: inside;
            padding: 12px 0 30px 19px;
        }

        &__profit-item {
            list-style-type: disc;
            padding-bottom: 9px;
            font-size: 16px;
            line-height: 130%;
        }

        &__button {
            display: flex;
            justify-content: center;
        }
    }
</style>
